var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('cabecalho-pagina',{attrs:{"titulo":_vm.$t('modulos.tipo_instrumento.titulos.listagem'),"total-registros":_vm.tabela.quantidadeItens,"titulo-botao-novo":_vm.$t('modulos.tipo_instrumento.titulos.novo'),"sem-botao-ok":!_vm.participanteLogadoConta &&
        !_vm.buscarPermissao('TipoInstrumento', 'Inserir'),"sem-filtro":!_vm.buscarPermissao('TipoInstrumento', 'Listar')},on:{"abrirNovo":_vm.abrirNovo,"filtrar":_vm.filtrar},scopedSlots:_vm._u([{key:"filtro",fn:function({ filtro }){return [_c('filtro',{attrs:{"filtro":filtro}})]}}])}),(
      _vm.podeExcluir &&
        _vm.participanteLogadoConta &&
        _vm.buscarPermissao('TipoInstrumento', 'Deletar')
    )?_c('botao-padrao',{staticClass:"my-2",attrs:{"outlined":"","color":"danger","disabled":!_vm.participanteLogadoConta},on:{"click":_vm.excluirRegistros}},[_c('v-icon',[_vm._v("$mdiTrashCanOutline")]),_vm._v(" "+_vm._s(_vm.$t('geral.botoes.excluir'))+" ")],1):_vm._e(),_c('tabela-padrao-prime-vue',{ref:"tabela",staticClass:"mt-2",attrs:{"colunas":_vm.tabela.colunas,"dados":_vm.tabela.dados,"mostrar-acoes":true,"filtros-com-colunas":"","mostrar-seletor":_vm.buscarPermissao('TipoInstrumento', 'Deletar'),"quantidade-itens":_vm.tabela.quantidadeItens,"por-pagina":_vm.tabela.porPagina,"quantidade-paginas":_vm.tabela.quantidadePaginas,"pagina":_vm.tabela.paginaAtual},on:{"paginar":_vm.listarRegistros,"filtrarGlobal":_vm.filtrarGlobal,"filtroGlobal":(v) => (_vm.filtroAplicado.filter = v),"nova-ordenacao":_vm.ordenacaoColuna},scopedSlots:_vm._u([{key:"acoes",fn:function({ slotProps }){return [_c('dropdown-padrao',{attrs:{"text":"","color":"secondary"},scopedSlots:_vm._u([{key:"botao",fn:function(){return [_c('v-icon',[_vm._v(" $dotsVertical ")])]},proxy:true}],null,true)},[(
            _vm.participanteLogadoConta &&
              _vm.buscarPermissao('TipoInstrumento', 'Editar')
          )?_c('dropdown-padrao-item',{on:{"click":function($event){return _vm.abrirEditar(slotProps.data)}}},[_vm._v(" "+_vm._s(_vm.$t('geral.botoes.editar'))+" ")]):_vm._e(),(
            _vm.participanteLogadoConta &&
              _vm.buscarPermissao('TipoInstrumento', 'Editar')
          )?_c('dropdown-padrao-item',{on:{"click":function($event){return _vm.abrirPontosCalibracao(slotProps.data)}}},[_vm._v(" "+_vm._s(_vm.$t('modulos.tipo_instrumento.tabela.pontos_de_calibracao'))+" ")]):_vm._e(),(_vm.buscarPermissao('TipoInstrumento', 'Visualizar'))?_c('dropdown-padrao-item',{on:{"click":function($event){return _vm.abrirVisualizar(slotProps.data)}}},[_vm._v(" "+_vm._s(_vm.$t('geral.botoes.visualizar'))+" ")]):_vm._e()],1)]}},{key:"tags",fn:function({ slotProps }){return [(slotProps.data.tag)?_c('coluna-multiplos-itens',{attrs:{"item":_vm.MontarViewTags(slotProps.data.tag)}}):_vm._e()]}}]),model:{value:(_vm.tabela.selecionados),callback:function ($$v) {_vm.$set(_vm.tabela, "selecionados", $$v)},expression:"tabela.selecionados"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }