<template>
  <div class="row">
    <input-text
      v-model="filtro.Nome"
      class="col-6"
      :label="$t('modulos.tipo_instrumento.formulario.nome')"
      :placeholder="$t('modulos.tipo_instrumento.formulario.nome')"
      em-filtro
    />
    <input-text
      v-model="filtro.tags"
      class="col-6"
      :label="$t('modulos.tipo_instrumento.formulario.tag')"
      :placeholder="$t('modulos.tipo_instrumento.formulario.tag')"
      em-filtro
    />
  </div>
</template>
<script>
export default {
  props: {
    filtro: {},
  },
};
</script>
