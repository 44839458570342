<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.tipo_instrumento.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.tipo_instrumento.titulos.novo')"
      :sem-botao-ok="
        !participanteLogadoConta &&
          !buscarPermissao('TipoInstrumento', 'Inserir')
      "
      :sem-filtro="!buscarPermissao('TipoInstrumento', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="
        podeExcluir &&
          participanteLogadoConta &&
          buscarPermissao('TipoInstrumento', 'Deletar')
      "
      class="my-2"
      outlined
      color="danger"
      :disabled="!participanteLogadoConta"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      :mostrar-acoes="true"
      filtros-com-colunas
      :mostrar-seletor="buscarPermissao('TipoInstrumento', 'Deletar')"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item
            v-if="
              participanteLogadoConta &&
                buscarPermissao('TipoInstrumento', 'Editar')
            "
            @click="abrirEditar(slotProps.data)"
          >
            {{ $t('geral.botoes.editar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="
              participanteLogadoConta &&
                buscarPermissao('TipoInstrumento', 'Editar')
            "
            @click="abrirPontosCalibracao(slotProps.data)"
          >
            {{ $t('modulos.tipo_instrumento.tabela.pontos_de_calibracao') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('TipoInstrumento', 'Visualizar')"
            @click="abrirVisualizar(slotProps.data)"
          >
            {{ $t('geral.botoes.visualizar') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
      <template v-slot:tags="{ slotProps }">
        <coluna-multiplos-itens
          v-if="slotProps.data.tag"
          :item="MontarViewTags(slotProps.data.tag)"
        />
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import helpers from '@common/utils/helpers';
import TipoInstrumentoService from '@common/services/cadastros/TipoInstrumentoService.js';
import ColunaMultiplosItens from '@/components/tabelas/ColunaMultiplosItens.vue';
import Filtro from './filtro.vue';

export default {
  components: {
    CabecalhoPagina,
    Filtro,
    ColunaMultiplosItens,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('modulos.tipo_instrumento.tabela.nome'),
            sortable: true,
          },
          {
            value: 'tags',
            text: this.$t('modulos.tipo_instrumento.tabela.tags'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      colunaOrdenada: '',
    };
  },

  computed: {
    ...mapGetters('Autenticacao', ['participanteLogadoConta']),

    podeExcluir() {
      return this.tabela.selecionados != 0;
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'TipoInstrumento', 'Listar');
    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.tipo_instrumento.titulos.listagem')
    );
    this.colunaOrdenada = 'Nome';
    this.listarRegistros();
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    filtrarGlobal() {
      if(!this.filtroAplicado.filter) this.tabela.paginaAtual = 1;
      this.listarRegistros();
    },
    filtrar(filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros(paginaAtual = this.tabela.paginaAtual,porPagina = this.tabela.porPagina) {
      this.tabela.porPagina = porPagina;
      this.salvarStateFiltros({
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: this.tabela.porPagina ?? porPagina,
        sort: this.colunaOrdenada,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      TipoInstrumentoService.listar(parametros)
        .then((res) => {
          this.tabela.selecionados = [];
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    MontarViewTags(tags) {
      return {
        value: tags.map((v) => {
          return { nome: v };
        }),
      };
    },
    excluirRegistros() {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        let promises = [];
        this.tabela.selecionados.forEach((item) => {
          promises.push(TipoInstrumentoService.excluir(item.id));
        });
        Promise.all(promises)
          .then(() => {
            this.listarRegistros(
              this.tabela.paginaAtual,
              this.tabela.porPagina
            );
            this.toastSucesso(
              this.$t(`modulos.tipo_instrumento.exclusao_sucesso`)
            );
            this.tabela.selecionados = [];
          })
          .catch((err) => {
            this.toastAlerta(
              this.$t(`geral.erros.${err?.response?.data?.errors[0]}`)
            );
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
            this.listarRegistros(
              this.tabela.paginaAtual,
              this.tabela.porPagina
            );
          });
      });
    },
    DeixarComoAtivoOuInativo(v) {
      return helpers.AtivoInativoEnum.find((el) => el.value == v);
    },
    abrirNovo() {
      this.$router.push({ name: 'tipo-instrumento-novo' });
    },
    abrirEditar(item) {
      this.$router.push({
        name: 'tipo-instrumento-editar',
        params: { id: item.id },
      });
    },
    abrirPontosCalibracao(item) {
      this.$router.push({
        name: 'tipo-instrumento-pontos',
        params: { id: item.id },
      });
    },
    abrirVisualizar(item) {
      this.$router.push({
        name: 'tipo-instrumento-visualizar',
        params: { id: item.id, visualizar: true },
      });
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro', {
        rota: this.$route.name,
        dados,
      });
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>
